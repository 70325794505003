header {
  // background: url("./../images/wave-orange.png");
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    max-width: 50%;
    color: #fff;
    font-size: 3vh;
    text-align: center;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
  }

  img {
    height: 100%;
    margin-top: 1vh;
  }
}
