.container {
  max-width: 100vw;
  height: 90vh;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  .box {
    // grid-area: 'box';
    aspect-ratio: 3 / 4;
    margin: auto;
    min-height: 0;
    min-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    //border: rgba(255, 255, 255, 0.2) solid 1px;
    // &:nth-child(3n + 2) {
    //   &:before {
    //     position: absolute;
    //     content: ' ';
    //     background-image: url('./../images/triangle-green.png');
    //     width: 100px;
    //     height: 100px;
    //     z-index: -1;
    //     bottom: -25%;
    //     right: -20%;
    //   }
    // }
    // &:nth-child(3n + 1) {
    //   &:before {
    //     position: absolute;
    //     content: ' ';
    //     background-image: url('./../images/dots-red.png');
    //     width: 100px;
    //     height: 100px;
    //     z-index: -1;
    //     bottom: -25%;
    //     right: -20%;
    //   }
    // }

    &:nth-last-of-type(3),
    &:nth-last-of-type(2),
    &:nth-last-of-type(1) {
      &:before {
        display: none;
      }
    }
    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    img.badge {
      position: absolute;
      width: 50px;
      height: 50px;
      bottom: 5px;
      right: 5px;
    }
  }
}
